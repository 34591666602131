import Img1 from "../../images/reviews/mytoys.png";
import Img3 from "../../images/reviews/metropolitan.png";
import Img2 from "../../images/reviews/hr.png";
import Img4 from "../../images/reviews/ruexpress.png";

export const SliderData = [
    {
        id: 0,
        author: "Александр Колдаев",
        post: "Руководитель по логистике и клиентскому сервису",
        img: Img1,
        alt: "myToys",
        description: [
            'Работаем 8 лет со «Стрекозой». Совместно настроенное информирование клиентов, позволило нам держать выкуп на достаточно высоком уровне все эти годы. За эти годы не мы не наблюдали сбоев в трекинге и информировании клиентов.',
            'Команда «Стрекозы» активно развивают новые механизмы информирования, новые механизмы тестируем через А/Б тесты. Подход к А/Б-тестами минимизирует потери для бизнеса, так как ребята работают на результат, то есть мы платим только в том случае, если они смогут поднять выкуп.'
        ],
    },
    {
        id: 1,
        author: "Кирилл Глазков",
        post: "Генеральный менеджер",
        img: Img3,
        alt: "Столичный магазин",
        description: [
            'Мы запустились со «Стрекозой» два месяца назад. Делаем А/Б-тест, сравниваем цепочку «Стрекозы» с нашей старой цепочкой. По предварительным результатам, цепочка «Стрекозы» повышает нам выкуп почтовых отправлений на 4,5%. Мы считаем это очень хорошим результатом. Это большие дополнительные деньги для нас.',
            'Особенно нам нравится, что «Стрекоза» умеет высчитывать вероятность выкупа посылки и подстраивает под нее информационные цепочки. Людям с низкой вероятностью выкупа уведомления отправляются чаще, тексты в них более настойчивые; людям с хорошей вероятностью выкупа уведомления отправляются реже и в более мягких лояльных формулировках.'
        ],

    },
    {
        id: 2,
        author: "Светлана Сергеева",
        post: "Операционный директор",
        img: Img2,
        alt: "ТВ-магазин",
        description: [
            'Мы работаем со «Стрекозой» 5 лет. Нам очень нравится, что ребята всегда идут навстречу и помогают нам с запросами по статистике и аналитике. Они оперативно реагируют на изменение сроков хранения: меняют цепочки, подсказывают, какие тексты и каналы связи лучше использовать для информирования и как его лучше настроить. В целом очень довольны их оперативностью и высоким уровнем клиентской поддержки.'
        ],

    },
    {
        id: 3,
        author: "Дмитрий Петраш",
        post: "Руководитель отдела маркетинга",
        img: Img4,
        alt: "Онлайн-гипермаркет РусЭкспресс",
        description: [
            'Мы работаем со Стрекозой несколько месяцев, сравниваем эффективность нашей собственной цепочки информирования и цепочки Стрекозы. Пока Стрекоза делает прирост 1,5% в выкупе. Это по Почте России и СДЭКу. Учитывая, что у нас и так высокий выкуп, считаем этот результат неплохим, хотя еще и стоит дождаться финальных результатов теста. Отдельно отмечу, что Стрекоза быстро реагирует на наши запросы по статистике, доработкам и всячески помогает со всеми вопросами, которые возникают в процессе работы.'
        ],

    }
]