import React from "react";
import { information } from "./InformationData";


export const Information = () =>  {

    const informations = [...information];

    return (
        <div className="ab-test-block">
            {informations.map((el,i) => (
                <div className="ab-test-wrap" key={i}>
                    {
                        el.id === 6 ? <p className="ab-test__title">Используем каскадные <br/> уведомления</p> :
                        el.id === 7 ? <p className="ab-test__title">Проверяем разные <br/> тембры голоса</p> :
                        el.id === 8 ? <p className="ab-test__title">Всё это - <span className="ab-test__title_block"> на основе скора</span></p> : <p className="ab-test__title">{el.title}</p>
                    }
                    <img src={el.img} alt=""/>
                    <div className="ab-test__description">
                        <p className="ab-test__text">{el.paragraph}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}