import ImgRetail from "../../images/cms-integrations/retail.png";
import ImgBitrix from "../../images/cms-integrations/bitrix.png";
import ImgWebasyst from "../../images/cms-integrations/webasyst.png";
import ImgInsales from "../../images/cms-integrations/insales.png";
import ImgMailRussia from "../../images/cms-integrations/mailrussia.png";
import ImgLeadVertex from "../../images/cms-integrations/leadvertex.png";
import ImgBeta from "../../images/cms-integrations/beta.png";
import Img1c from "../../images/cms-integrations/1c.png";
import ImgAmiroCRM from "../../images/cms-integrations/amirocms.png";
import ImgAutoPay from "../../images/cms-integrations/autopay.png";
import ImgOpenCart from "../../images/cms-integrations/opencart.png";
import ImgWarehouse from "../../images/cms-integrations/warehouse.png";
import ImgYandex from "../../images/cms-integrations/yandex.png";
import ImgPrestaShop from "../../images/cms-integrations/prestashop.png";
import ImgAdvantShop from "../../images/cms-integrations/advantshop.png";
import ImgApi from "../../images/cms-integrations/api.png";

export const integration = [
    {
        id: 0,
        link: "https://wiki.deliverycrm.ru/index.php?title=%D0%9C%D0%BE%D0%B4%D1%83%D0%BB%D1%8C_%D0%B8%D0%BD%D1%82%D0%B5%D0%B3%D1%80%D0%B0%D1%86%D0%B8%D0%B8_%D1%81_RetailCRM",
        img: ImgRetail,
        alt: "retailCRM",
    },
    {
        id: 1,
        link: "https://marketplace.1c-bitrix.ru/solutions/rlab.sendparcel/",
        img: ImgBitrix,
        alt: "Bitrix",
    },
    {
        id: 2,
        link: "https://www.webasyst.ru/store/plugin/shop/deliverycrm/",
        img: ImgWebasyst,
        alt: "Webasyst",
    },
    {
        id: 3,
        link: "https://www.insales.ru/collection/apps/product/otsledit-posylkurf",
        img: ImgInsales,
        alt: "InSales",
    },
    {
        id: 4,
        link: "https://wiki.deliverycrm.ru/index.php?title=Интеграция_otpravka.pochta.ru",
        img: ImgMailRussia,
        alt: "Почта России Отправка",
    },
    {
        id: 5,
        link: "https://wiki.deliverycrm.ru/index.php?title=Интеграция_с_Leadvertex",
        img: ImgLeadVertex,
        alt: "LeadVertex",
    },
    {
        id: 6,
        link: "",
        img: ImgBeta,
        alt: "Фулфилмент Бета Про",
    },
    {
        id: 7,
        link: "https://wiki.deliverycrm.ru/index.php?title=Интеграция_с_1С",
        img: Img1c,
        alt: "1C",
    },
    {
        id: 8,
        link: "http://www.amiro.ru/market/catalog/free/otsledit-posylku-treker-dostavki-312",
        img: ImgAmiroCRM,
        alt: "AmiroCMS",
    },
    {
        id: 9,
        link: "https://wiki.deliverycrm.ru/index.php?title=Интеграция_с_E-AutoPay",
        img: ImgAutoPay,
        alt: "E-AutoPay",
    },
    {
        id: 10,
        link: "https://www.opencart.com/index.php?route=marketplace/extension/info&extension_id=32270&filter_search=deliveryCRM",
        img: ImgOpenCart,
        alt: "Opencart",
    },
    {
        id: 11,
        link: "https://wiki.deliverycrm.ru/index.php?title=Интеграция_с_МойСклад",
        img: ImgWarehouse,
        alt: "МойСклад",
    },
    {
        id: 12,
        link: "",
        img: ImgYandex,
        alt: "Яндекс",
    },
    {
        id: 13,
        link: "https://wiki.deliverycrm.ru/index.php?title=Интеграция_с_PrestaShop",
        img: ImgPrestaShop,
        alt: "PrestaShop",
    },
    {
        id: 14,
        link: "https://www.advantshop.net/help/pages/module-deliverycrm",
        img: ImgAdvantShop,
        alt: "AdvantShop",
    },
    {
        id: 15,
        link: "https://wiki.deliverycrm.ru/index.php?title=Интеграция_через_API",
        img: ImgApi,
        alt: "Api",
    },
]