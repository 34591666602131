import React from "react";
import { integration } from "./IntegrationData";

export function IntegrationServices() {

    const integrations = [...integration]

    return (
        <>
            <h2 className="integration-head">Подключим вас за 15 минут, если вы пользователь этих сервисов</h2>
            <ul className="integration-wraw__list">
                {integrations.map((params,i) => (
                    <li className="integration-wrap__item" key={i}>
                        {params.id === 6 || params.id === 12
                            ?
                            <span>
                                <img className="integration-wrap__img" src={params.img} alt={params.alt} />
                            </span>
                            :
                            <a href={params.link} target="_blank" rel="noopener noreferrer">
                                <img className="integration-wrap__img" src={params.img} alt={params.alt} />
                            </a>
                        }
                    </li>
                ))}
            </ul>
        </>
    )
}