import React from 'react';

export const ModalSlider = ({ openItem, setOpenItem }) => {

    function closeModal(e) {
        if(e.target.id === "overlay") {
            setOpenItem(null)
        }

    }

    if(!openItem) return null

    return (
        <>
            <div id="overlay" className="overlay-slider" onClick={closeModal} role="presentation">
                <div className="modal-slider">
                    <div className="modal-slider-block">
                        <button onClick={() => setOpenItem(null)} className="icon-close-modal" />
                        <div className="reviews-item__head">
                            <div className="reviews-item__name">
                                <span className="reviews-item__company-author">{openItem.author}</span>
                                <span className="reviews-item__company-post">{openItem.post}</span>
                            </div>
                            <div className="reviews-item__picture">
                                <img className="reviews-item__img" src={openItem.img} alt={openItem.alt}/>
                            </div>
                        </div>
                        <div className="reviews-item__content">
                            {openItem.description.map((array, j) => (
                                <p className="reviews-item__company-description" key={j}>
                                    {array}
                                </p>
                            ))}
                        </div>
                    </div>
                    <button onClick={() => setOpenItem(null)} className="btn-slider-modal">Закрыть окно</button>
                </div>
            </div>
        </>

    );
};