import React from "react";
import { social } from "./SocialData";

export function Social() {

    const socials = [...social];

    return (
        <>
            <h2>Мы провели более 300 экспериментов и потратили 2 года, чтобы создать идеальное информирование о доставке для вас.</h2>
            <p className="social__paragraph">Мы работаем со всеми доступными каналами связи:</p>
            <div className="social-block">
                {socials.map((el, i) => (
                    <div className="social-block__item" key={i}>
                        <img className="social-block__img" src={el.img} alt={el.name} />
                        <span className="social-block__name">{el.name}</span>
                    </div>
                ))}
            </div>
        </>
    )
}