import Social1 from "../../images/social/odnoklassniki.svg"
import Social2 from "../../images/social/vk.svg"
import Social3 from "../../images/social/whatsapp.svg"
import Social4 from "../../images/social/viber.svg"
import Social5 from "../../images/social/telegram.svg"
import Social6 from "../../images/social/mail.svg"
import Social7 from "../../images/social/sms.svg"
import Social8 from "../../images/social/robocall.svg"

export const social = [
    {
        id: 0,
        name: 'Одноклассники',
        img: Social1,
    },
    {
        id: 1,
        name: 'VKontakte',
        img: Social2,
    },
    {
        id: 2,
        name: 'WhatsApp',
        img: Social3,
    },
    {
        id: 3,
        name: 'Viber',
        img: Social4,
    },
    {
        id: 4,
        name: 'Telegram',
        img: Social5,
    },
    {
        id: 5,
        name: 'Email',
        img: Social6,
    },
    {
        id: 6,
        name: 'SMS',
        img: Social7,
    },
    {
        id: 7,
        name: 'RoboCall',
        img: Social8,
    }

]