import information1 from "../../images/information/information1.png";
import information2 from "../../images/information/information2.png";
import information3 from "../../images/information/information3.png";
import information4 from "../../images/information/information4.png";
import information5 from "../../images/information/information5.png";
import information6 from "../../images/information/information6.png";
import information7 from "../../images/information/information7.png";
import information8 from "../../images/information/information8.png";
import information9 from "../../images/information/information9.png";

export const information = [
    {
        id: 0,
        title: "Экспериментируем с текстами",
        img: information1,
        paragraph: "В одном из экспериментов нам удалось поднять выкуп на 1%, добавив 1 слово в текст уведомления! Экспериментируйте, смотрите, что сработает у вас.",
    },
    {
        id: 1,
        title: "Фильтруем лишнее",
        img: information2,
        paragraph: "У служб доставки много ошибочных и мусорных статусов. Мы знаем, какие уведомления действительно важны для получателей.",
    },
    {
        id: 2,
        title: "Выбираем имя отправителя",
        img: information3,
        paragraph: "Настраиваем для SMS, социальных сетей и мессенджеров. Имя влияет на открываемость, читаемость и, как следствие, на лояльность покупателей и выкуп ваших отправлений.",
    },
    {
        id: 3,
        title: "Выбираем номер телефона",
        img: information4,
        paragraph: "Знаем, когда и с какого номера позвонить, чтобы клиент снял трубку: с местного, московского или с 8-800.",
    },
    {
        id: 4,
        title: "Настраиваем частотность",
        img: information5,
        paragraph: "Выбираем, когда уведомлять клиента: весь период доставки или только во время хранения посылки в пункте выдачи. Увеличиваем интенсивность, когда срок хранения подходит к концу.",
    },
    {
        id: 5,
        title: "Отправляем вовремя",
        img: information6,
        paragraph: "Учитываем при отправке уведомлений местное время клиента и выходные дни.",
    },
    {
        id: 6,
        title: "Используем каскадные уведомления",
        img: information7,
        paragraph: "Начинаем с одного уведомления в приоритетный канал связи. Отправляем следующее, только если первое сообщение осталось непрочитанным.",
    },
    {
        id: 7,
        title: "Проверяем разные тембры голоса",
        img: information8,
        paragraph: "Тестируем пол и тембр роботизированного голоса: нежный или строгий женский, брутальный или мягкий мужской и т.д.",
    },
    {
        id: 8,
        title: `Всё это - на основе скора`,
        img: information9,
        paragraph: "Скор — вероятность выкупа посылки, вычисляется автоматически. С его помощью сегментируем аудиторию.",
    }
]

