import React from "react";

import "swiper/swiper-bundle.css";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import { SliderData } from "./SliderData";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);


export const Slider = ({ setOpenItem }) => {

    const sliders = [...SliderData];

    return (
        <>
            <Swiper
                spaceBetween={20}
                speed={300}
                slidesPerView={1}
                allowTouchMove={false}
                navigation
                scrollbar={{ draggable: true }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                    },
                    760: {
                        slidesPerView: 2,
                    },
                }}
            >
                {sliders.map((num, i) => (
                    <SwiperSlide key={i}>
                        <div className="reviews-item">
                            <div className="reviews-item-block">
                                <div className="reviews-item__head">
                                    <div className="reviews-item__name">
                                        <span className="reviews-item__company-author">{num.author}</span>
                                        <span className="reviews-item__company-post">{num.post}</span>
                                    </div>
                                    <div className="reviews-item__picture">
                                        <img className="reviews-item__img" src={num.img} alt={num.alt}/>
                                    </div>
                                </div>
                                <div className="reviews-item__content" onClick={() => setOpenItem(num)} role="presentation">
                                    {num.description.map((array, j) => (
                                        <p className="reviews-item__company-description" key={j}>
                                            {array}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}